<template>
    <PModal :can-cancel="canCancel" :footer-actions="actions" slot-class="p-0">
        <template #title><span>Корхонани танланг</span></template>

        <b-overlay :show="loading">
            <div
                :key="org.tin || org.pinfl"
                v-for="org in organizations"
                class="org-item d-flex align-items-center"
                @click="clickOrg(org)"
            >
                <div class="flex-grow-1 mr-2">
                    <div v-if="org.tin" class="tg-1">{{ $l("platon.eimzo_tin", "СТИР") }} {{ org.tin }}</div>

                    <div v-else-if="org.pinfl" class="tg-1">
                        {{ $l("platon.eimzo_pinfl", "ЖШШИР") }} {{ org.pinfl }}
                    </div>

                    <div class="font-weight-bold">{{ org.name }}</div>
                </div>

                <div
                    v-if="(org.tin || org.pinfl).toString() === currentOrg"
                    class="text-success"
                    style="font-size: 22px"
                >
                    <i class="fas fa-check-circle"></i>
                </div>
            </div>
        </b-overlay>
    </PModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "PickUserOrganization",

    props: {
        organizations: {
            type: Array
        },

        onSelected: {
            type: Function,
            default: () => () => {}
        },

        canCancel: {
            type: Boolean
        }
    },

    data() {
        return {
            loading: false
        }
    },

    computed: {
        ...mapGetters({
            user: "platon/user"
        }),

        /**
         * @return {ModalAction[]}
         */
        actions() {
            if (this.canCancel) {
                return [{ text: this.$l("platon.cancel", "Бекор қилиш") }]
            } else {
                return [
                    {
                        text: this.$l("platon.logout", "Тизимдан чиқиш"),
                        icon: "fa fa-sign-out-alt",
                        handler: () => {
                            this.$store.dispatch("platon/logout")

                            return false
                        }
                    }
                ]
            }
        },

        currentOrg() {
            return this.user && this.user.orgId && this.user.orgId.toString()
        }
    },

    methods: {
        ...mapActions({
            switchOrganization: "platon/switchOrganization"
        }),

        /**
         * @param {{name: string, tin: ?string, pinfl: ?string}} org
         */
        async clickOrg(org) {
            try {
                this.loading = true
                let orgId = org.tin || org.pinfl
                await this.switchOrganization({ orgId })

                this.onSelected(orgId)
                this.$emit("close")
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.org-item {
    border-bottom: 2px solid #eeeeee;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
}
</style>
